import React, { ReactElement } from 'react';
import { Box, Flex } from 'theme-ui';
import Container from '../../components/container';
import FooterLogo from './FooterLogo';

const MiniFooter = (): ReactElement => {
  return (
    <Box as="footer" sx={{ bg: '#1E1E1E' }} className={'scn-footer'}>
      <Container
        isBodyContainer={false}
        containerSx={{
          pb: [0, 0, 0, 0],
          pt: [0, 0, 0, 0],
        }}
      >
        <Flex
          data-id="boxa"
          sx={{
            color: 'textSecondary',
            py: ['22px', '22px', '35px'],
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
          }}
        >
          <Box
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '19px',
            }}
          >
            powered by
          </Box>
          <FooterLogo />
        </Flex>
      </Container>
    </Box>
  );
};

export default MiniFooter;
